import * as React from "react";
import Masonry from "react-responsive-masonry";

import Container from "../container/container";

import * as styledComponents from "./projects.module.scss";

//Import Images
import photo1 from "../../images/project1.jpg";
import photo2 from "../../images/project2.jpg";
import photo3 from "../../images/cleaning2.jpg";

//Create Matrix
const images = [photo1, photo2, photo3];

const ExampleDynamicColumns = () => {
  return (
    <div className={styledComponents.projects}>
      <Container>
        <h3 className={styledComponents.title}>PROJETS RÉCENTS</h3>
        <Masonry columnsCount={3} gutter="20px">
          {images.map((image, i) => (
            <img
              key={i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt=""
            />
          ))}
        </Masonry>
      </Container>
    </div>
  );
};

export default ExampleDynamicColumns;
