import * as React from "react";
import Layout from "../components/layout";
import Header from "../components/header/header";
import Services from "../components/services/services";
import Projects from "../components/projects/projects";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <Services />
      <Projects />
    </Layout>
  );
};

export default IndexPage;
