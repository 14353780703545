import * as React from "react";
import * as styledComponets from "./servicephoto.module.scss";

const ServicePhoto = ({ children, givenBackgroundImage }) => {
  return (
    <div className={styledComponets.styledCard}>
      <img src={givenBackgroundImage} alt="" />

      <h6 className={styledComponets.styledText}>{children}</h6>
    </div>
  );
};

export default ServicePhoto;
