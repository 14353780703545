import * as React from "react";
import Container from "../container/container";
import Button from "../button";
import Link from "../link";

import * as styledComponents from "./header.module.scss";

const Header = () => {
  return (
    <header>
      <Container>
        <div className={styledComponents.half}>
          <div>
            <h1>Homme à tout faire</h1>
            <h6>Pour la maison, les bureaux et les bâtiments commerciaux</h6>
            <Link to="/nos-services">
              <Button
                givenBackground="var(--bg4)"
                givenColor="var(--bg1)"
                givenFontFamily="TK3"
                givenPadding="10px 25px"
              >
                NOS SERVICES
              </Button>
            </Link>

            <Link to="tel:15146535548">
              <Button
                givenBackground="var(--bg3)"
                givenColor="var(--bg1)"
                givenFontFamily="TK3"
                givenPadding="10px 25px"
              >
                DEMANDE DE DEVIS
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
