import * as React from "react";
import ServicePhoto from "../servicePhoto/servicePhoto";
import Container from "../container/container";
import Button from "../button";

//Images
import cleaning from "../../images/cleaning.jpg";
import bushes from "../../images/bushes.jpg";
import washer from "../../images/washer.jpg";
import pave from "../../images/pave.jpg";
import deck from "../../images/deck.jpg";
import reno from "../../images/smallrepair.jpg";

//Css
import * as styledComponents from "./services.module.scss";

const Services = () => {
  return (
    <Container>
      <div className={styledComponents.flex}>
        <p>QU'EST-CE QUE ON PEUT FAIRE POUR VOUS ?</p>
        <ServicePhoto givenBackgroundImage={cleaning}>
          Ménage après construction
        </ServicePhoto>
        <ServicePhoto givenBackgroundImage={bushes}>Arbustes</ServicePhoto>
        <ServicePhoto givenBackgroundImage={washer}>Lavage</ServicePhoto>
        <ServicePhoto givenBackgroundImage={pave}>
          Réparation Pavé-uni
        </ServicePhoto>
        <ServicePhoto givenBackgroundImage={deck}>Deck en bois</ServicePhoto>
        <ServicePhoto givenBackgroundImage={reno}>
          Petite réparation
        </ServicePhoto>
      </div>

      <div className={styledComponents.button}>
        <Button
          Button
          givenBackground="var(--bg3)"
          givenColor="var(--bg1)"
          givenFontFamily="TK3"
          givenPadding="10px 25px"
        >
          VOIR TOUS LES SERVICES
        </Button>
      </div>
    </Container>
  );
};

export default Services;
